
import { defineComponent, Ref, ref } from 'vue';
import router from '@/router';
import {
    listPagination,
    ListHeaderItem,
    RequestData
} from '@/components/common/list';
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import deviceStatus from '@/components/view/common/device-status/index.vue';

const headers: Array<ListHeaderItem> = [{
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Community',
    label: WordList.ProperAllCommunity
}, {
    name: 'Installer',
    label: WordList.NavInHtmlCommunity
}, {
    name: 'Manage',
    label: WordList.ProperAllTextRoleDistributor
}, {
    name: 'Type',
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
    type: 'customize'
}, {
    name: 'IP Address',
    label: WordList.TabelDeviceInHtmlIPAddress
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus,
    type: 'customize'
}, {
    name: 'SipAccount',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Last Connected',
    label: WordList.PersonaldeviceListTanleBindingTime
}];

const searchKeyList = [{
    label: WordList.TabelPersonDeviceInHtmlMAC,
    name: 'MAC'
}, {
    label: WordList.ProperAllCommunity,
    name: 'Community'
}, {
    label: WordList.NavInHtmlCommunity,
    name: 'Installer'
}, {
    label: WordList.MulListUpdateListTanleAccount,
    name: 'SipAccount'
}, {
    label: WordList.ProperAllTextRoleDistributor,
    name: 'Manage'
}];

export default defineComponent({
    components: {
        listPagination,
        deviceIcon,
        deviceStatus
    },
    setup() {
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: 'getalldevice',
            param: {
                serchKey: 'MAC',
                serchValue: ''
            }
        });

        const goToDeviceInfo = (item: object) => {
            localStorage.setItem('detail', JSON.stringify(item));
            router.push('/superIndex/communityDeviceDetail');
        };
        return {
            headers,
            listRequestData,
            searchKeyList,
            updateToList,
            goToDeviceInfo
        };
    }
});
